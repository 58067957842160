import React, { useEffect, useRef, useState } from 'react'
import logoIcon from '../../img/logo-color.svg';
import { useNavigate } from "react-router-dom";
import '../../css/navbar.css'
import { useAuth } from './AuthContext';
import Dropdown from 'react-bootstrap/Dropdown';

const  Navbar = () => {
  let navigate = useNavigate();
  const {userType,logout} = useAuth()

  const goToContactUsPage = () => {
    navigate('/contact')
  }

  const goToExtensionPage = () => {
      window.open('https://chromewebstore.google.com/detail/viable-lab/jdicfkeaccokmbakdgpecnpomhenfacl', '_blank');
  }

  const handleLogout = () => {
    logout();
  }

  const navigateTo = (link) => {
    let url = `/${userType}`+ link
    navigate(url)
  }

  return (
    <div className='navigation-section'>
      <div className='navigation-common'>
        <a href="/"><div className='logo'><img src={logoIcon} alt="Logo" height="100%" /></div></a>
        <div className='desktop-only'>
          <div className='flex-row gap-1'>
          {!localStorage.getItem('token') &&
            <div className='button button-style1' onClick={goToExtensionPage}>Get Extension</div>
          }
            <div className='button button-style1' onClick={goToContactUsPage}>Contact Us</div>
            {localStorage.getItem('token') && 
            <div className='button button-style2' onClick={handleLogout}>Log Out</div>
            }
          </div>
        </div>
        

        <div className='mobile-only'>
         <Dropdown>
            <Dropdown.Toggle>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={goToContactUsPage}>Contact Us</Dropdown.Item>
              {localStorage.getItem('token') && 
                <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        
        </div>
      
      </div>
      {userType === 'student' && 
      <div className='navigation-student'>
      {/* <div className='button button-style3' onClick={()=>navigateTo('/homepage')}>Home</div> */}
      {/* <div className='button button-style3' onClick={() => navigateTo('/job-dashboard')}>Job Dashboard</div>
      <div className='button button-style3' onClick={() => navigateTo('/courses')}>Recommended Courses</div>
      <div className='button button-style3' onClick={() => navigateTo('/project-dashboard')}>Project Dashboard</div> */}
      <div className='button button-style3' onClick={() => navigateTo('/blogs')}>Blogs</div>
      <div className='button button-style3' onClick={() => navigateTo('/notes')}>Notes</div>
      <div className='button button-style3' onClick={() => navigateTo('/profile')}>My Profile</div>
      </div>}

      {userType === 'company' && 
      <div className='navigation-student'>
      <div className='button button-style3' onClick={()=>navigateTo('/homepage')}>Home</div>
      <div className='button button-style3' onClick={() => navigateTo('/dashboard')}>Dashboard</div>
      {/* <div className='button button-style3' onClick={() => navigateTo('/courses')}>Recommended Courses</div>
      <div className='button button-style3' onClick={() => navigateTo('/project-dashboard')}>Project Dashboard</div>
      <div className='button button-style3' onClick={() => navigateTo('/blogs')}>Blogs</div>
      <div className='button button-style3' onClick={() => navigateTo('/profile')}>My Profile</div> */}
      </div>}
    </div>
  )
}

export default  Navbar;
